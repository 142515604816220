import React, { useState } from 'react'
import axios from "axios";
import './style.scss'
import { navigate } from 'gatsby-link';


const ResetPassword = ({activate}) => {
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false)

  let query = null;
  if (typeof window !== 'undefined') {
    query = new URLSearchParams(window?.location?.search)
  }
  const reset_password_token = query?.get('token')

  const resetPassword = (e) => {
    e.preventDefault();
    setError(false)
    setLoading(true)

    const url = `${process.env.GATSBY_API_URL}/api/auth/password`
    axios.put(url, {auth: { password, password_confirmation: password, reset_password_token } }).then((res) => {
      setPassword('')
      setLoading(false)
      setError(false)
      setForgotPasswordSubmitted(true)
      setTimeout(() => { navigate('/login')}, 2500)
    }).catch((err) => {
      setLoading(false)
      setError('There was an error setting your password. Try again.')
    })
  }

  return (
    <main className="forgot-password-page main-content grid-section" role="main">
      <div className="login-wrapper">
        <form method="post">
          <div className="container">
            <div className="grid__item title one-whole text-center">
              <h1 className="heading">
                { activate ? 'Activate Account' : 'Reset Password' }
              </h1>
            </div>
            <p className='subheader'>Enter your new password.</p>
            <div className="input-container">
              <label htmlFor="CustomerEmail" className="label">
                New Password
              </label>
              <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} id="CustomerPass" className="input-full" placeholder="Enter your new password" autoCorrect="off" autoCapitalize="off" autoFocus="" />

              <button disabled={password === '' || loading || forgotPasswordSubmitted} className='login-button reset-password-button' onClick={(e) => !loading && resetPassword(e)}>
                { activate ? 'Set Password' : 'Reset Password' }
              </button>

              {error &&
                <>
                  {
                    activate ? 
                    <h5 className='text-center mt-5 error-text'>
                      There was an error.<br/>
                      Your account may already be activated.
                      Try <a href='/login'>login</a> or <a class="no-wrap" href='/forgot-password'>reset password</a> 
                    </h5> :
                    <h5 className='text-center mt-5 error-text'>{error}</h5>
                  }
                </>
              }

              {forgotPasswordSubmitted &&
                <div>
                  <h5 className='text-center mt-5'>Password successfully set</h5>
                  <h5 className='text-center mt-3'>Redirecting to <a href='/login'>login</a></h5>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    </main >
  )
}

export default ResetPassword
